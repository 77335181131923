import './styles.css';

import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import MapGL, { Layer, MapRef, Marker, Popup, ScaleControl, Source } from 'react-map-gl';

import { Spinner } from '../../common/components/Spinner';
import {
  useAppDispatch,
  useAppSelector,
  useCallResizeOnMapWhenContainerSizeChange,
  useGetFilteredEvents,
} from '../../common/hooks';
import { useGetPortEventsPortCallsQuery, VesselPosition } from '../../store/apis/ais-api';
import { Anomalies } from '../../store/apis/vessel-event-api';
import { setSelectedEvent } from '../event-feed/slice';
import { AisQualityLayer } from '../map-layers/AisQualityLayer';
import { EmissionLayer } from '../map-layers/EmissionLayer';
import { useGetTransformAisTileRequestCallback } from '../map-layers/hooks';
import { useGetMapProps } from '../switch-map-style/hooks';
import {
  useGetLatestPortsInformation,
  useGetSelectedPosition,
  useGetVesselPositionsForSelectedDatesQuery,
} from '../vessel-details-view/hooks';
import { useRepositionMapWhenPositionsChange } from './hooks';
import { setMapInstance as setMapInstanceAction, setSelectedPositionTimestamp } from './slice';
import {
  getAnomalyCoodinatesFromPositions,
  getAnomalyVesselRoutesLayerProps,
  getAnomalyVesselRoutesSourceProps,
  getCalculatedHeadingForPosition,
  getPositionMarkerIcon,
  getPositionMarkerIconWithSource,
  getVesselOutlineCoordinates,
  getVesselOutlineSourceProps,
  getVesselRoutesSourceProps,
  isAnomalyPosition,
  vesselOutlineLayerProps,
  vesselRoutesLayerProps,
} from './utils';

type Props = {
  imo: string;
};

export const VesselMap: React.FC<Props> = ({ imo }) => {
  const dispatch = useAppDispatch();

  const { selectedPositionTimestamp, showVesselOutline, sourceMarkers } = useAppSelector((state) => state.vesselMap);
  const { startDate, endDate } = useAppSelector((state) => state.vesselMapDateSelect);
  const { nextPort, lastPort } = useAppSelector((state) => state.vesselDetails);
  const { aisAccessToken } = useAppSelector((state) => state.mapLayers);
  const dateState = useAppSelector((state) => state.vesselMapDateSelect);
  const portData = useGetPortEventsPortCallsQuery(
    { imo: Number(imo) || undefined, from: dateState.startDate, to: dateState.endDate, limit: 100 },
    { skip: !imo }
  );

  const { anomalies, casualties } = useGetFilteredEvents();
  const selectedPosition = useGetSelectedPosition();

  const [mapInstance, setMapInstance] = useState<MapRef | null>(null);
  const mapProps = useGetMapProps('vessel');

  const [isOpen, setIsOpen] = useState(true);
  const [isCasualtyMarkerOpen, setIsCasualtyMarkerOpen] = useState(false);

  useCallResizeOnMapWhenContainerSizeChange(mapInstance);
  const transformAisTileRequest = useGetTransformAisTileRequestCallback();

  useEffect(() => {
    if (!mapInstance) return;
    dispatch(setMapInstanceAction(mapInstance));
  }, [dispatch, mapInstance]);

  const positionsQuery = useGetVesselPositionsForSelectedDatesQuery(Number(imo));
  const ports = positionsQuery.currentData?.results?.[0]?.ports;
  useGetLatestPortsInformation(ports?.last_port, ports?.next_port, ports?.current_port);
  useRepositionMapWhenPositionsChange(positionsQuery.currentData?.results?.[0] as VesselPosition | undefined);

  const onCasualtyClickHandler = useCallback(
    async (casualty: any) => {
      dispatch(setSelectedEvent({ id: casualty.id as number, type: 'casualty' }));
    },
    [dispatch]
  );

  const onPositionClickHandler = useCallback(
    async (timestamp: string | null, selected: boolean) => {
      dispatch(setSelectedPositionTimestamp(selected ? null : timestamp));
    },
    [dispatch]
  );

  const positions = positionsQuery.currentData?.results?.[0]?.positions;
  const vesselRefPoint = positionsQuery.currentData?.results?.[0]?.vessel?.refpoint;

  const markers = useMemo(() => {
    if (!positions?.length || positionsQuery.isFetching) {
      return null;
    }

    // Positions are sorted latest to last, we reverse it in order to make newer position markers appear above older ones.
    return [...positions].reverse().map((position, index) => {
      const selected = position.timestamp === selectedPositionTimestamp;

      const isAnomalyMarker =
        position.timestamp &&
        anomalies.currentData &&
        isAnomalyPosition(position.timestamp, anomalies.currentData as Anomalies[]);

      let heading: number | null | undefined = position.heading;
      const hasHeading = heading !== undefined && heading !== null;

      if (heading === undefined || heading === null) {
        heading = getCalculatedHeadingForPosition(position, positions);
      }
      const imgSrc = sourceMarkers
        ? getPositionMarkerIconWithSource(position.source, !!heading)
        : getPositionMarkerIcon(!!isAnomalyMarker, hasHeading, !!heading);

      const isLastPosition = index === positions.length - 1;
      const isLastPositionAndNoSelected = isLastPosition && !selectedPosition;
      const currentPositionIsSelected = selectedPosition && position.timestamp === selectedPosition.timestamp;
      const highlightPosition = isLastPositionAndNoSelected || currentPositionIsSelected;
      let marker = undefined;
      if (position?.latitude && position?.longitude) {
        marker = (
          <Marker
            key={position.timestamp}
            longitude={position.longitude}
            latitude={position.latitude}
            anchor="center"
            style={{ zIndex: 2 }}
            onClick={() => onPositionClickHandler(position.timestamp || null, selected)}
            rotation={heading || undefined}
          >
            <img
              alt="marker"
              src={imgSrc}
              className={`w-[16px] cursor-pointer ${
                highlightPosition ? 'border-black border-2 rounded-full w-[25px]' : ''
              }`}
              title={`${dayjs.utc(position.timestamp).format('YYYY-MM-DD HH:mm')} UTC / ${position.speed} kn`}
            />
          </Marker>
        );
      }

      if (isLastPosition && showVesselOutline) {
        const outlineCoordinates = getVesselOutlineCoordinates(vesselRefPoint, position, heading);
        if (outlineCoordinates) {
          return (
            <>
              <Source {...getVesselOutlineSourceProps(outlineCoordinates)}>
                <Layer {...vesselOutlineLayerProps} />
              </Source>
              {marker}
            </>
          );
        }
      }

      return marker;
    });
  }, [
    anomalies.currentData,
    onPositionClickHandler,
    positions,
    positionsQuery.isFetching,
    selectedPosition,
    selectedPositionTimestamp,
    vesselRefPoint,
  ]);

  const portMarkers = useMemo(() => {
    if (!lastPort && !nextPort && !portData.currentData) return null;
    if (!positions) return null;
    if (selectedPosition && selectedPosition?.timestamp !== positions[0]!.timestamp) return null;

    const portIconList = [lastPort, nextPort].map((port) => {
      if (!port || !port.geo_point) return null;
      const [longitude, latitude] = port.geo_point.coordinates || [];

      return (
        <Marker
          key={port.id + port.destinationType}
          longitude={longitude as number}
          latitude={latitude as number}
          onClick={() => setIsOpen(!isOpen)}
          anchor="bottom"
          style={{ zIndex: 1 }}
        >
          <img
            alt="port-marker"
            src="/assets/images/markers/port.svg"
            className={`w-[26px] cursor-pointer`}
            title={port.name}
          />
          {isOpen && (
            <Popup
              closeButton={false}
              closeOnClick={false}
              longitude={longitude as number}
              latitude={latitude as number}
              anchor="bottom"
              offset={35}
              className="map__marker__popup"
            >
              {port.destinationType}: {port.name}
            </Popup>
          )}
        </Marker>
      );
    });

    if (portData.currentData?.results) {
      portIconList.push(
        ...portData.currentData.results.map((port) => {
          if (!port || !port.port_latitude || !port.port_longitude) return null;
          return (
            <Marker
              key={`${port.port_id}${port.arrival_time}`}
              longitude={port.port_longitude as number}
              latitude={port.port_latitude as number}
              onClick={() => setIsOpen(!isOpen)}
              anchor="bottom"
              style={{ zIndex: 1 }}
            >
              <img
                alt="port-marker"
                src="/assets/images/markers/port.svg"
                className={`w-[26px] cursor-pointer`}
                title={port.port_name}
              />
              {isOpen && (
                <Popup
                  closeButton={false}
                  closeOnClick={false}
                  longitude={port.port_longitude as number}
                  latitude={port.port_latitude as number}
                  anchor="bottom"
                  offset={35}
                  className="map__marker__popup"
                >
                  {port.port_name}
                </Popup>
              )}
            </Marker>
          );
        })
      );
    }
    return portIconList;
  }, [lastPort, nextPort, selectedPosition, positions, isOpen, portData]);

  const casualtyMarkers = useMemo(() => {
    if (!casualties) return null;

    return casualties.currentData?.map((casualty) => {
      if (!casualty) return null;
      if (!dayjs(casualty.casualty_date).isBetween(dayjs(startDate), dayjs(endDate), 'day', '[]')) return null;

      return (
        casualty.longitude !== null &&
        casualty.latitude !== null && (
          <Marker
            key={casualty.id}
            longitude={casualty.longitude as number}
            latitude={casualty.latitude as number}
            anchor="bottom"
            style={{ zIndex: 3 }}
            onClick={() => setIsCasualtyMarkerOpen(!isCasualtyMarkerOpen)}
          >
            <img
              alt="port-marker"
              src="/assets/images/markers/casualty.svg"
              className={`w-[26px] cursor-pointer`}
              title={'Casualty on ' + casualty.casualty_date}
            />
            {isCasualtyMarkerOpen && (
              <Popup
                closeButton={false}
                closeOnClick={false}
                longitude={casualty.longitude as number}
                latitude={casualty.latitude as number}
                anchor="bottom"
                offset={35}
                className="map__marker__popup"
              >
                {
                  <div
                    className="flex flex-col text-center px-1 cursor-pointer"
                    onClick={() => onCasualtyClickHandler(casualty)}
                  >
                    <span> {'Casualty on ' + casualty.casualty_date}</span>
                    <span>Click to view the casualty event</span>
                  </div>
                }
              </Popup>
            )}
          </Marker>
        )
      );
    });
  }, [casualties, endDate, isCasualtyMarkerOpen, onCasualtyClickHandler, startDate]);

  const vesselRouteCoordinates =
    positions?.map((position) => {
      return [position.longitude as number, position.latitude as number];
    }) || [];

  let anomalyVesselRouteCoordinates: number[][][] = [];
  if (positions?.length && anomalies.currentData) {
    anomalyVesselRouteCoordinates = getAnomalyCoodinatesFromPositions(positions, anomalies.currentData as Anomalies[]);
  }

  return (
    <MapGL
      {...mapProps}
      ref={setMapInstance}
      transformRequest={transformAisTileRequest}
      key={aisAccessToken}
      dragRotate={false}
      touchZoomRotate={false}
    >
      {positionsQuery.isFetching && (
        <div className="absolute w-full h-full flex items-center justify-center">
          <div className="bg-primary/90 rounded-[4px] flex items-center justify-center p-10">
            <Spinner className="w-8 h-8" />
          </div>
        </div>
      )}
      {markers}
      {portMarkers}
      {casualtyMarkers}
      <Source {...getVesselRoutesSourceProps(vesselRouteCoordinates)}>
        <Layer {...vesselRoutesLayerProps} />
      </Source>
      {anomalyVesselRouteCoordinates.map((coordinates, index) => (
        <Source
          key={index}
          {...getAnomalyVesselRoutesSourceProps(`anomaly-vessel-routes-source-${index}`, coordinates)}
        >
          <Layer {...getAnomalyVesselRoutesLayerProps(`anomaly-vessel-routes-layer-${index}`)} />
        </Source>
      ))}
      <AisQualityLayer />
      <EmissionLayer />
      <ScaleControl position="bottom-right" maxWidth={200} unit="nautical" />
    </MapGL>
  );
};
